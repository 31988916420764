import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Button, Collapse, Empty } from "antd";
import {
  FileAddOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileJpgOutlined,
  CreditCardOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import Box from "@mui/material/Box";
import ModalDoc from "../Modal/ModalDoc";
import { useDispatch, useSelector } from "react-redux";
import { modaldocReducer } from "../../Redux/Reducers/Modal/modaldoc";
import { List } from "antd";
import Delete from "../../assets/icon/delete.svg";
import Edit from "../Icons/Edit";
import Dowolland from "../Icons/Dowolland";
import { fileReducer } from "../../Redux/Reducers/Document/document";
import { Steps } from "antd";
import { withTranslation } from "react-i18next";
import MainHeader from "../MainHeader/MainHeader";
import axios from "axios";
import url from "../../url.json";
import headers from "../../config/headers";
import { Alert } from "antd";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import ModalDocDel from "../Modal/ModalDocDel";
import ModalDocEdit from "../Modal/ModalDocEdit";
const { Panel } = Collapse;
const { Step } = Steps;
function DocumentShow({ t }) {
  const [editable, setEditable] = useState(false);
  const lan = useSelector((state) => state.langReducer);
  const [datares, setDatares] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [documents, setDocument] = useState();
  const modaldoc = useSelector((state) => state.modaldoc);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  const [shows, setShows] = useState(false);
  const [showse, setShowse] = useState(false);
  useEffect(() => {
    axios
      .get(url.url + `/application/${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        if (res.data.data.editable == 1) setEditable(true);
      });
  }, [lan]);
  useEffect(() => {
    axios
      .get(url.url + `/category?application_id=${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        setDocument(res.data.data);
      });
  }, [shows, showse, modaldoc, lan]);
  const fileRef = React.createRef();
  const [file, setFile] = useState();
  const [title, settitle] = useState();
  const [categoryid, setCategoryid] = useState();
  const openFile = (a, b) => {
    settitle(a);
    setCategoryid(b);
    fileRef.current.click();
  };
  const handleCancel = () => {
    setShows(false);
  };
  const handleCancele = () => {
    setShowse(false);
  };
  function handleChange(event) {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      showModal();
    }
  }
  useEffect(() => {
    dispatch(fileReducer(file));
  }, [file]);
  const showModal = () => {
    dispatch(modaldocReducer(true));
  };
  const onChange = (key) => {};
  function selecticon(file) {
    if (file[file.length - 1] == "jpg") return <FileJpgOutlined />;
    if (file[file.length - 1] == "pdf") return <FilePdfOutlined />;
    if (file[file.length - 1] == "doc") return <FileWordOutlined />;
    if (file[file.length - 1] == "docx") return <FileWordOutlined />;
    if (file[file.length - 1] == "ppt") return <FilePptOutlined />;
    if (file[file.length - 1] == "pptx") return <FilePptOutlined />;
  }
  function lists(res) {
    const dataval = res.files.map((item) => {
      const file = item?.file.split(".");
      return { obj: item, icon: selecticon(file) };
    });

    return (
      <List
        locale={{ emptyText: <Empty description={t("No data")} /> }}
        itemLayout="horizontal"
        dataSource={dataval}
        renderItem={(res) => (
          <List.Item>
            <List.Item.Meta
              avatar={<div className="">{res.icon}</div>}
              title={
                <span className="mt-3">
                  <a href={res.obj.file} download>
                    {res.obj.name}
                  </a>
                </span>
              }
            />
          </List.Item>
        )}
      />
    );
  }
  // const genExtra = (a, b) => <FileAddOutlined onClick={() => openFile(a, b)} />;
  const handleNext = () => {
    navigate({
      pathname: "/account/applications/pay",
      search: `?${createSearchParams({
        id: searchParams.get("id"),
        con_id: searchParams.get("con_id"),
      })}`,
    });
  };
  return (
    <div className="coll-holder">
      <ModalDocDel show={shows} handleCancel={handleCancel} res={datares} />
      <ModalDocEdit show={showse} handleCancel={handleCancele} res={datares} />

      <div className="coll-holder">
        <Collapse
          className="coll-holder"
          defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8"]}
          onChange={onChange}
        >
          <ModalDoc file={file} title={title} categoryid={categoryid} />
          <input
            type="file"
            id="file"
            ref={fileRef}
            onChange={handleChange}
            style={{ display: "none" }}
          />
          {documents?.map((res, i) => (
            <Panel header={` ${res.name} . ${res?.description}`} key={i + 1}>
              {lists(res)}
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
}
export default withTranslation()(DocumentShow);
