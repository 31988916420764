import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import MainHeader from "../../components/MainHeader/MainHeader";
import Box from "@mui/material/Box";
import DocumentShow from "../../components/Document/DocumentShow";
import "../AddApplic/styles.scss";
import {
  FileDoneOutlined,
  SolutionOutlined,
  FileWordOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import { Steps, Tabs } from "antd";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import ShowApplic from "../../components/ShowApplic/ShowApplic";
import Xronology from "../../components/Xronology/Xronology";
import Payshow from "../../components/Pay/Payshow";
import { Button, notification, Tooltip } from "antd";
import Chat from "../../components/Chat/Chat";
import Intruction from "../../components/Intruction/Intruction";
import ShowInstruction from "../../components/Intruction/ShowInstruction";
function ShowApplication({ t }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { TabPane } = Tabs;
  const handlenext = () => {
    navigate("/account/applications/document");
  };
  const handleback = () => {
    navigate("/account/applications");
  };
  function selects(key) {
    switch (key) {
      case "1":
        return (
          <div className="form-body xran-wrapp ">
            <div className="app-show">
              <ShowApplic handlenext={handlenext} id={searchParams.get("id")} />
            </div>
            <div className="xron-show">
              <Xronology />
            </div>
          </div>
        );
      case "2":
        return (
          <div className="form-body ">
            <div>
              <DocumentShow />
            </div>
          </div>
        );
      case "3":
        return (
          <div className="form-body ">
            <div>
              <ShowInstruction />
            </div>
          </div>
        );
      // case "4":
      //   return (
      //     <div className="form-body ">
      //       <div>
      //         <Chat />
      //       </div>
      //     </div>
      //   );

      default:
        break;
    }
  }
  const [keys, setKeys] = useState("1");

  const changeTab = (activeKey) => {
    setKeys(activeKey);
  };
  return (
    <div>
      <div className="tabb">
        <Box sx={{ width: "100%" }}>
          <Tabs defaultActiveKey="1" size={"large"} onChange={changeTab}>
            <TabPane
              tab={
                <span>
                  <SolutionOutlined />
                  {t("Application")}
                </span>
              }
              key="1"
            />
            <TabPane
              tab={
                <span>
                  <FileDoneOutlined />
                  {t("Instruction")}
                </span>
              }
              key="3"
            />
            <TabPane
              tab={
                <span>
                  <FileWordOutlined />
                  {t("Document")}
                </span>
              }
              key="2"
            />
            {/* <TabPane
              animated
              tab={
                <span>
                  <WechatOutlined /> {t("Chat")}
                </span>
              }
              key="4"
            /> */}
          </Tabs>
        </Box>
      </div>
      {selects(keys)}
      <div className="btnn-holderr">
        <Button type="primary" size={20} onClick={handleback}>
          {t("Back")}
        </Button>
      </div>
    </div>
  );
}
export default withTranslation()(ShowApplication);
