import React, { useEffect, useState } from "react";
import axios from "axios";
import "./NotOpen.scss";
import { withTranslation } from "react-i18next";
import url from "../../url.json";
import MainHeader from "../../components/MainHeader/MainHeader.js";
import headers from "../../config/headers";
import dateFormat from "dateformat";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { noticReducer } from "../../Redux/Reducers/notic";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

function NotOpen({ t }) {
  const lan = useSelector((state) => state.langReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  useEffect(() => {
    axios
      .get(url.url + `/notice/${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        setData(res.data.data);
        dispatch(noticReducer(res.data.data.text));
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }, [lan]);
  return (
    <>
      <MainHeader title={t("Notifications")}></MainHeader>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vis Nefrit | {t("Notifications")}</title>
      </Helmet>
      <div className="card-opened">
        <div className="card-opened__body">
          <h1 className="card-opened__title">{data?.text}</h1>
          <div className="card-opened__names">
            <span className="card-opened__name">
              {t("Department")}: {data?.department}
            </span>
            <span className="card-opened__name-app">
              {t("Applic")}: {data?.application.code}
            </span>
          </div>
          <p className="card-opened__desc">{data?.message}</p>
          <span className="card-opened__date">
            {dateFormat(data?.created_at, "dd.mm.yyyy")}
          </span>
        </div>
      </div>
    </>
  );
}
export default withTranslation()(NotOpen);
