import React, { useEffect, useState } from "react";
import "../Contract/contract.scss";
import url from "../../url.json";
import { useDispatch, useSelector } from "react-redux";
import MainHeader from "../../components/MainHeader/MainHeader.js";
import { createSearchParams, useNavigate } from "react-router-dom";
import { UploadOutlined, CreditCardOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import axios from "axios";
import headers from "../../config/headers";
import { Empty } from "antd";
import "antd/dist/antd.css";
import { Button, Menu } from "antd";
import Dowolland from "../../components/Icons/Dowolland";
import { Alert, Spin } from "antd";
import { Table } from "reactstrap";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ModalApplic from "../../components/Modal/ModalApplic";
import { modalapplicReducer } from "../../Redux/Reducers/Modal/modalapplic";
import dateFormat from "dateformat";
import { Helmet } from "react-helmet-async";
function MySertificates({ t }) {
  const navigate = useNavigate();
  const [datas, setData] = useState();
  const [dataId, setDataId] = useState();
  const lan = useSelector((state) => state.langReducer);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  useEffect(() => {
    axios
      .get(url.url + `/certificate/legal-entity`, {
        headers: headers(),
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }, [lan.value]);
  function handleShow(id) {
    axios
      .get(url.url + `/application/${id}`, {
        headers: headers(),
      })
      .then((res) => {
        localStorage.setItem("applic", res.data.data.id);
        if (res.contract_id == null)
          navigate({
            pathname: "/account/applications/show",
            search: `?${createSearchParams({
              id: res.data.data.id,
            })}`,
          });
        else
          navigate({
            pathname: "/account/applications/show",
            search: `?${createSearchParams({
              id: res.data.data.id,
              con_id: res.data.data.contract_id,
            })}`,
          });
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }
  const dispatch = useDispatch();
  const fileRef = React.createRef();
  const [file, setFile] = useState();
  const showModal = () => {
    dispatch(modalapplicReducer(true));
  };
  const openFile = (a) => {
    setDataId(a);
    fileRef.current.click();
  };
  function handleChange(event) {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      showModal();
    }
  }
  const menu = (res) => {
    return (
      <Menu
        items={[
          {
            label: t("Upload"),
            key: "1",
            icon: <UploadOutlined width={18} height={18} />,
            onClick: () => openFile(res.id),
          },
          {
            label: t("Dowolland"),
            key: "2",
            icon: <Dowolland width={18} height={18} />,
            onClick: () => {
              window.location.href = res?.file;
            },
          },
          {
            label: t("Payments"),
            key: "3",
            icon: <CreditCardOutlined width={18} height={18} />,
            onClick: () =>
              navigate({
                pathname: "/account/contract/paylist",
                search: `?${createSearchParams({
                  id: res.id,
                })}`,
              }),
          },
        ]}
      />
    );
  };
  return (
    <>
      <MainHeader title={t("My Sertificats")}></MainHeader>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vis Nefrit | {t("My Sertificats")}</title>
      </Helmet>
      {datas?.result.length > 0 ? (
        <div className="resp table-responsive">
          <Table responsive="sm" style={{ backgroundColor: "white" }}>
            <thead className="thead">
              <tr className="thead">
                <th className="thhhs">#</th>
                <th className="thhhs">{t("Registration number")}</th>
                <th className="thhhs">{t("Applications")}</th>
                <th className="thhhs">{t("Drug")}</th>
                <th className="thhhs">{t("Created at")}</th>
                <th className="thhhs">{t("File")}</th>
              </tr>
            </thead>
            <tbody>
              {datas?.result.map((res, i) => (
                <tr key={i}>
                  <td className="tddds">{i + 1}</td>
                  <td className="tddds">{res.number}</td>
                  <td className="tddds">{res.application.code}</td>
                  <td className="tddds">{res.drug.name}</td>

                  <td className="tddds">
                    {dateFormat(res.created_at, "dd.mm.yyyy")}
                  </td>
                  <td className="tddds">
                    {res.file ? (
                      <Button
                        className="btnnn"
                        type="primary"
                        shape="round"
                        icon={<FileDownloadOutlinedIcon />}
                        size={12}
                        onClick={() => (window.location.href = res.file)}
                      >
                        {t("Download")}
                      </Button>
                    ) : (
                      <Button
                        className="btnnn"
                        type="primary"
                        shape="round"
                        disabled
                        icon={<FileDownloadOutlinedIcon />}
                        size={12}
                      >
                        {t("Download")}
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 500,
            backgroundColor: "white",
            borderRadius: 15,
          }}
        >
          <Empty description={t("No data")} />
        </div>
      )}
    </>
  );
}
export default withTranslation()(MySertificates);
