import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Button, Collapse, Empty } from "antd";
import {
  FileOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileJpgOutlined,
  FileDoneOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import Box from "@mui/material/Box";
import ModalDoc from "../Modal/ModalDoc";
import { useDispatch, useSelector } from "react-redux";
import { modaldocReducer } from "../../Redux/Reducers/Modal/modaldoc";
import { List } from "antd";
import Delete from "../../assets/icon/delete.svg";
import Edit from "../Icons/Edit";
import Dowolland from "../Icons/Dowolland";
import { fileReducer } from "../../Redux/Reducers/Document/document";
import { Steps } from "antd";
import { withTranslation } from "react-i18next";
import MainHeader from "../MainHeader/MainHeader";
import axios from "axios";
import url from "../../url.json";
import headers from "../../config/headers";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import ModalDocDel from "../Modal/ModalDocDel";
import ModalDocEdit from "../Modal/ModalDocEdit";
import { CaretRightOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import DocumentShow from "./DocumentShow";
const { Panel } = Collapse;
const { Step } = Steps;
function Document({ t }) {
  const lan = useSelector((state) => state.langReducer);
  const [datares, setDatares] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [documents, setDocument] = useState();
  const modaldoc = useSelector((state) => state.modaldoc);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  const [shows, setShows] = useState(false);
  const [showse, setShowse] = useState(false);
  const [checks, setChecks] = useState();
  const [activekey, setactivekey] = useState(false);
  useEffect(() => {
    axios
      .get(
        url.url + `/category?application_id=${localStorage.getItem("applic")}`,
        { headers: headers() }
      )
      .then((res) => {
        setDocument(res.data.data);
      });
  }, [shows, showse, modaldoc, lan]);
  const fileRef = React.createRef();
  const [file, setFile] = useState();
  const [title, settitle] = useState();
  const [categoryid, setCategoryid] = useState();
  const [activeKey, setActiveKey] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
  ]);
  const openFile = (a, b) => {
    fileRef.current.value = "";

    settitle(a);
    setCategoryid(b);
    fileRef.current.click();
  };
  const handleCancel = () => {
    setShows(false);
    setFile();
    fileRef.current.value = "";
    dispatch(modaldocReducer(false));
  };
  const handleCancele = () => {
    setShowse(false);
    setFile();
    fileRef.current.value = "";
  };
  function handleChange(event) {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      showModal();
    }
  }
  useEffect(() => {
    dispatch(fileReducer(file));
  }, [file]);
  const showModal = () => {
    dispatch(modaldocReducer(true));
  };
  const onChange = (key) => {};
  function selecticon(file) {
    if (file[file.length - 1] == "jpg") return <FileJpgOutlined />;
    if (file[file.length - 1] == "pdf") return <FilePdfOutlined />;
    if (file[file.length - 1] == "doc") return <FileWordOutlined />;
    if (file[file.length - 1] == "docx") return <FileWordOutlined />;
    if (file[file.length - 1] == "ppt") return <FilePptOutlined />;
    if (file[file.length - 1] == "pptx") return <FilePptOutlined />;
    else return <FileOutlined />;
  }

  const genExtra = (a, b) => (
    <div onClick={(e) => e.stopPropagation()}>
      {
        <div
          onClick={() => {
            openFile(a, b);
          }}
          className="btn btn-primary btn-sm"
        >
          {t("Add")}
        </div>
      }
    </div>
  );
  const handleNext = () => {
    const data = { id: searchParams.get("id") };
    axios
      .get(url.url + `/check`, { headers: headers(), params: data })
      .then((res) => {
        if (res.data.data?.error) {
          for (let i in res.data.data?.message) {
            toast.error(res.data.data?.message[i]);
          }
        } else {
          toast.success(res.data.data?.message);
        }
        setChecks(res.data.data);
      });
  };
  function lists(res, i) {
    const dataval = res.files.map((item) => {
      const file = item?.file.split(".");
      return { obj: item, icon: selecticon(file) };
    });

    return (
      <List
        locale={{ emptyText: <Empty description={t("No data")} /> }}
        itemLayout="horizontal"
        dataSource={dataval}
        renderItem={(res) => (
          <List.Item
            actions={[
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setActiveKey(`${i}`.toString());
                  setShowse(true);
                  setDatares(res);
                }}
              >
                <Edit width={18} height={18} />
              </div>,
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShows(true);
                  setDatares(res);
                }}
              >
                <img className="btn-icon" src={Delete} alt="delete icon" />
              </div>,
              <a href={res.obj?.file} target="blank">
                <Dowolland width={18} height={18} />
              </a>,
            ]}
          >
            <List.Item.Meta
              avatar={<div className="">{res.icon}</div>}
              title={
                <div className="d-flex justify-content-between align-center">
                  <div className="">{res.obj.name}</div>
                  {res?.obj?.status == 2 ? (
                    <span
                      style={{ fontSize: 12 }}
                      className="btn btn-outline-danger p-1"
                    >
                      {t("File_rejected")}
                    </span>
                  ) : res?.obj?.status == 4 ? (
                    <span
                      style={{ fontSize: 12 }}
                      className={`btn btn-outline-success p-1 `}
                    >
                      {t("File_approved")}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              }
            />
          </List.Item>
        )}
      />
    );
  }
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    axios
      .get(url.url + `/application/${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        if (res.data.data.editable == 1) setEditable(true);
      });
  }, [lan]);

  return (
    <div>
      <ModalDocDel show={shows} handleCancel={handleCancel} res={datares} />
      <ModalDocEdit show={showse} handleCancel={handleCancele} res={datares} />

      <MainHeader title={t("Edit application")} />
      <div className="add-application">
        <Box sx={{ width: "100%" }}>
          <Steps>
            <Step
              status="finish"
              title={t("Application")}
              icon={<SolutionOutlined />}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (searchParams.get("con_id"))
                  navigate({
                    pathname: "/account/applications/edit",
                    search: `?${createSearchParams({
                      id: searchParams.get("id"),
                      con_id: searchParams.get("con_id"),
                    })}`,
                  });
                else
                  navigate({
                    pathname: "/account/applications/edit",
                    search: `?${createSearchParams({
                      id: searchParams.get("id"),
                    })}`,
                  });
              }}
            />
            <Step
              status="finish"
              title={t("Instruction")}
              icon={<FileDoneOutlined />}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!editable) {
                  if (searchParams.get("con_id"))
                    navigate({
                      pathname: "/account/applications/instruction",
                      search: `?${createSearchParams({
                        id: searchParams.get("id"),
                        con_id: searchParams.get("con_id"),
                      })}`,
                    });
                  else
                    navigate({
                      pathname: "/account/applications/instruction",
                      search: `?${createSearchParams({
                        id: searchParams.get("id"),
                      })}`,
                    });
                } else {
                  if (searchParams.get("con_id"))
                    navigate({
                      pathname: "/account/applications/instruction",
                      search: `?${createSearchParams({
                        id: searchParams.get("id"),
                        con_id: searchParams.get("con_id"),
                      })}`,
                    });
                  else
                    navigate({
                      pathname: "/account/applications/instruction",
                      search: `?${createSearchParams({
                        id: searchParams.get("id"),
                      })}`,
                    });
                }
              }}
            />
            <Step
              status="process"
              title={t("Document")}
              icon={<FileWordOutlined />}
              style={{ cursor: "pointer" }}
            />
          </Steps>
        </Box>
      </div>
      {editable ? (
        <Collapse
          bordered={false}
          defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8"]}
          onChange={onChange}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="site-collapse-custom-collapse"
        >
          <ModalDoc
            handleCancel={handleCancel}
            file={file}
            title={title}
            categoryid={categoryid}
          />
          <input
            type="file"
            id="file"
            ref={fileRef}
            onChange={handleChange}
            style={{ display: "none" }}
          />
          {documents?.map((res, i) => (
            <Panel
              header={`${res.name}. ${res?.description}`}
              key={`${i + 1}`}
              extra={genExtra(res?.description, res.id)}
            >
              {lists(res, i + 1)}
            </Panel>
          ))}
        </Collapse>
      ) : (
        <DocumentShow />
      )}
      <div className="btn-gr d-flex ">
        <div className="mr-1">
          <Button
            className="doc-btnn"
            type="primary"
            size={20}
            onClick={() => navigate(-1)}
          >
            {t("last stage")}
          </Button>
        </div>
        {editable ? (
          <div className="d-flex flex-wrap">
            <div className="mr-1">
              <Button
                className="doc-btnn"
                type="primary"
                size={20}
                onClick={() => navigate("/account/applications")}
              >
                {t("Cancel")}
              </Button>
            </div>
            <div className="btn-pr-form mr-0">
              <Button
                className="doc-btnn"
                type="primary"
                size={20}
                onClick={handleNext}
              >
                {t("Submit application")}
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* {searchParams.get("con_id") ? (
          <div className="btn-next-form">
            <Button
              type="primary"
              size={20}
              onClick={() =>
                navigate({
                  pathname: "/account/applications/pay",
                  search: `?${createSearchParams({
                    id: searchParams.get("id"),
                    con_id: searchParams.get("con_id"),
                  })}`,
                })
              }
            >
              {t("Next")}
            </Button>
          </div>
        ) : (
          ""
        )} */}
      </div>
    </div>
  );
}
export default withTranslation()(Document);
