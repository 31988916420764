import React from "react";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import List from "../../components/List/List.js";
import MainHeader from "../../components/MainHeader/MainHeader.js";
function Applications({ t }) {
  const navigate = useNavigate();
  return (
    <>
      <MainHeader title={t("Application list")}></MainHeader>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vis Nefrit | {t("Application list")}</title>
      </Helmet>
      <div>
        <List />
      </div>
    </>
  );
}
export default withTranslation()(Applications);
