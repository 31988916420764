import React, { useEffect, useState } from "react";
import "./Reg.scss";
import "../Landing.scss";
import File from "../../../assets/icon/file.svg";
import { withTranslation } from "react-i18next";
import axios from "axios";
import url from "../../../url.json";
import headers from "../../../config/headers";
import dateFormat from "dateformat";
import { Empty, Pagination, Spin } from "antd";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
function RegulLand({ t }) {
  const lan = useSelector((state) => state.langReducer);

  const [datas, setDatas] = useState();
  useEffect(() => {
    axios.get(url.url + "/regulation", { headers: headers() }).then((res) => {
      setDatas(res.data.data);
    });
  }, [lan.value]);
  const handlePage = (e) => {
    axios
      .get(url.url + `/regulation?page=${e}`, { headers: headers() })
      .then((res) => {
        setDatas(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  };
  return (
    <div className="landing-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title data-rh="true">Nefrit | {t("Regulations")}</title>
      </Helmet>
      <div className="landing-reg">
        <h2 className="landing-reg__title"> {t("Regulations")}</h2>
        {datas?.result.length > 1 ? (
          <div>
            {" "}
            <div className="pagin">
              <Pagination
                current={1}
                style={{ borderRadius: 15 }}
                onChange={(e) => handlePage(e)}
                defaultCurrent={1}
                total={datas?.pagination.total}
              />
            </div>
            {datas?.result.map((res, i) => (
              <div className="landing-reg__box" key={i}>
                <img src={File} alt="file icon" width={50} height={50} />
                <div className="landing-reg__right">
                  <a
                    className="landing-reg__right-title"
                    href={res.file}
                    target="_blank"
                    download
                  >
                    {res.title}
                  </a>
                  {/* <p>{res.text}</p> */}
                  <div className="landing-reg__bottom">
                    <span className="landing-reg__info reg-calendar">
                      {dateFormat(res.date, "dd.mm.yyyy")}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : datas ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: 500,
              backgroundColor: "white",
            }}
          >
            <Empty description={t("No data")} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: 500,
              backgroundColor: "white",
            }}
          >
            <Spin tip={t("Loading...")}>
              <Empty description={t("No data")} />
            </Spin>
          </div>
        )}
      </div>
    </div>
  );
}
export default withTranslation()(RegulLand);
