import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import MainHeader from "../../components/MainHeader/MainHeader";
import Box from "@mui/material/Box";
import "../AddApplic/styles.scss";
import { Button, Alert } from "antd";
import {
  SolutionOutlined,
  FileWordOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Steps, Empty, Spin } from "antd";
import url from "../../url.json";
import axios from "axios";
import EditForm from "../../components/AddForm1/EditForm";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import headers from "../../config/headers";
import ShowApplic from "../../components/ShowApplic/ShowApplic";
import { Helmet } from "react-helmet-async";
import EditForm2 from "../../components/AddForm1/Edite";
function EditApplication({ t }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [editable, setEditable] = useState(false);
  const [data, setData] = useState();
  const { Step } = Steps;
  useEffect(() => {
    axios
      .get(url.url + `/application/${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        setData(res.data.data);
        if (res.data.data.editable == 1) setEditable(true);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }, []);
  const handleback = () => {
    navigate("/account/applications");
  };
  if (data)
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Vis Nefrit | {t("Edit application")}</title>
        </Helmet>
        <MainHeader title={t("Edit application")} />
        {!editable ? (
          <Alert
            className="mb-3 "
            message={t("info_edit_application")}
            type="info"
            showIcon
          />
        ) : (
          ""
        )}
        <div className="add-application">
          <Box sx={{ width: "100%" }}>
            <div className="bordered">
              <Steps>
                <Step
                  status="process"
                  title={t("Application")}
                  icon={<SolutionOutlined />}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (searchParams.get("con_id"))
                      navigate({
                        pathname: "/account/applications/edit",
                        search: `?${createSearchParams({
                          id: searchParams.get("id"),
                          con_id: searchParams.get("con_id"),
                        })}`,
                      });
                    else
                      navigate({
                        pathname: "/account/applications/edit",
                        search: `?${createSearchParams({
                          id: searchParams.get("id"),
                        })}`,
                      });
                  }}
                />
                <Step
                  status="wait"
                  title={t("Instruction")}
                  style={{ cursor: "pointer" }}
                  icon={<FileDoneOutlined />}
                  onClick={() => {
                    if (!editable) {
                      if (searchParams.get("con_id"))
                        navigate({
                          pathname:
                            "/account/applications/insturictioneditable",
                          search: `?${createSearchParams({
                            id: searchParams.get("id"),
                            con_id: searchParams.get("con_id"),
                          })}`,
                        });
                      else
                        navigate({
                          pathname:
                            "/account/applications/insturictioneditable",
                          search: `?${createSearchParams({
                            id: searchParams.get("id"),
                          })}`,
                        });
                    } else {
                      if (searchParams.get("con_id"))
                        navigate({
                          pathname: "/account/applications/instruction",
                          search: `?${createSearchParams({
                            id: searchParams.get("id"),
                            con_id: searchParams.get("con_id"),
                          })}`,
                        });
                      else
                        navigate({
                          pathname: "/account/applications/instruction",
                          search: `?${createSearchParams({
                            id: searchParams.get("id"),
                          })}`,
                        });
                    }
                  }}
                />
                <Step
                  status="wait"
                  title={t("Document")}
                  style={{ cursor: "pointer" }}
                  icon={<FileWordOutlined />}
                  onClick={() => {
                    if (!editable) {
                      if (searchParams.get("con_id"))
                        navigate({
                          pathname: "/account/applications/documenteditable",
                          search: `?${createSearchParams({
                            id: searchParams.get("id"),
                            con_id: searchParams.get("con_id"),
                          })}`,
                        });
                      else
                        navigate({
                          pathname: "/account/applications/documenteditable",
                          search: `?${createSearchParams({
                            id: searchParams.get("id"),
                          })}`,
                        });
                    } else {
                      if (searchParams.get("con_id"))
                        navigate({
                          pathname: "/account/applications/document",
                          search: `?${createSearchParams({
                            id: searchParams.get("id"),
                            con_id: searchParams.get("con_id"),
                          })}`,
                        });
                      else
                        navigate({
                          pathname: "/account/applications/document",
                          search: `?${createSearchParams({
                            id: searchParams.get("id"),
                          })}`,
                        });
                    }
                  }}
                />
              </Steps>
            </div>
          </Box>
        </div>
        <div className="form-body">
          {editable ? (
            <EditForm2 id={searchParams.get("id")} />
          ) : (
            <div>
              <ShowApplic id={searchParams.get("id")} />{" "}
              <div className="btnn-holderr">
                <Button type="primary" size={20} onClick={handleback}>
                  {t("Back")}
                </Button>
                <Button
                  type="primary"
                  className="ml-2"
                  size={20}
                  onClick={() =>
                    navigate({
                      pathname: "/account/applications/insturictioneditable",
                      search: `?${createSearchParams({
                        id: searchParams.get("id"),
                        con_id: searchParams.get("con_id"),
                      })}`,
                    })
                  }
                >
                  {t("Next")}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  else
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 500,
          backgroundColor: "white",
          borderRadius: 12,
        }}
      >
        <Spin tip={t("Loading...")}>
          <Empty description={t("No data")} />
        </Spin>
      </div>
    );
}
export default withTranslation()(EditApplication);
