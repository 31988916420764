import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@material-ui/core";
import { useTranslation, withTranslation } from "react-i18next";
import LanEn from "../../assets/icon/eng.svg";
import LanRu from "../../assets/icon/rus.svg";
import LanUz from "../../assets/icon/uzb.svg";
import { ListItemText, ListItemAvatar } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { langReducer } from "../../Redux/Reducers/Lang/langReducer";
import "./header.scss";
function SelectLan() {
  const selectlan = useSelector((state) => state.langReducer);
  const { t, i18n } = useTranslation();
  const [Icons, setIcons] = useState();
  const [Title, setTitle] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectlan.value == "uz") {
      setIcons(LanUz);
      setTitle("O'Z");
      i18n.changeLanguage("uz");
    }
    if (selectlan.value == "ru") {
      setIcons(LanRu);
      setTitle("РУ");
      i18n.changeLanguage("ru");
    }
    if (selectlan.value == "en") {
      setIcons(LanEn);
      setTitle("EN");
      i18n.changeLanguage("en");
    }
  }, [selectlan.value]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (selectlan.value == "uz") {
      setIcons(LanUz);
      setTitle("O'Z");
    }
    if (selectlan.value == "ru") {
      setIcons(LanRu);
      setTitle("РУ");
    }
    if (selectlan.value == "en") {
      setIcons(LanEn);
      setTitle("EN");
    }
  }, [anchorEl]);

  const handleCloses = (lng) => {
    setAnchorEl(null);
    localStorage.setItem("lan", lng);
    dispatch(langReducer(lng));
    i18n.changeLanguage(lng);
  };
  const handleClose = (lng) => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <img className="lang" src={Icons} />
          <h2 className="sidebar-entrance__detail-title">{Title}</h2>
        </div>
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleCloses("uz")}>
          <ListItemAvatar>
            <img
              className="fll"
              src={LanUz}
              style={{ width: 22, height: 15 }}
            />
          </ListItemAvatar>
          <ListItemText>{"O'Z"}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleCloses("ru")}>
          <ListItemAvatar>
            <img
              className="fll"
              src={LanRu}
              style={{ width: 22, height: 15 }}
            />
          </ListItemAvatar>
          <ListItemText>{"РУ"}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleCloses("en")}>
          <ListItemAvatar>
            <img
              className="fll"
              src={LanEn}
              style={{ width: 22, height: 15 }}
            />
          </ListItemAvatar>
          <ListItemText>{"EN"}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
export default withTranslation()(SelectLan);
