import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import MainHeader from "../../components/MainHeader/MainHeader";
import Box from "@mui/material/Box";
import "./styles.scss";
import AddForm from "../../components/AddForm1/AddForm";
import {
  SolutionOutlined,
  FileWordOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Empty, Spin, Steps } from "antd";
import url from "../../url.json";
import axios from "axios";
import { useNavigate, createSearchParams } from "react-router-dom";
import ApplicNone from "../Applications/ApplicNone";
import headers from "../../config/headers";
import { Helmet } from "react-helmet-async";

function Addapplication({ t }) {
  const navigate = useNavigate();
  const { Step } = Steps;
  const handleNext = (e) => {
    navigate({
      pathname: "/account/applications/instruction",
      search: `?${createSearchParams({
        id: e,
      })}`,
    });
  };
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(url.url + `/legal-entity/profile`, {
        headers: headers(),
      })
      .then((res) => {
        setData(res.data.data);
      });
  }, []);
  if (data) {
    if (data?.legal_entity?.blocked_at === null)
      if (data?.legal_entity?.verified_at !== null)
        return (
          <div>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Vis Nefrit | {t("Application filling")}</title>
            </Helmet>
            <MainHeader title={t("Application filling")} />
            <div className="add-application">
              <Box sx={{ width: "100%", borderRadius: 12 }}>
                <Steps className="rounded bordered">
                  <Step
                    status="process"
                    title={t("Application")}
                    icon={<SolutionOutlined />}
                  />
                  <Step
                    status="wait"
                    title={t("Instruction")}
                    icon={<FileDoneOutlined />}
                  />
                  <Step
                    status="wait"
                    title={t("Document")}
                    icon={<FileWordOutlined />}
                  />
                </Steps>
              </Box>
            </div>
            <div className="form-body">
              <AddForm handlenext={(e) => handleNext(e)} />
            </div>
          </div>
        );
      else
        return (
          <ApplicNone
            title={t(
              "Your organization information is still under review. You cannot enter information at this time"
            )}
          />
        );
    else {
      return <ApplicNone title={t("You are currently blocked")} />;
    }
  } else
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 500,
          backgroundColor: "white",
          borderRadius: 12,
        }}
      >
        <Spin tip={t("Loading...")}>
          <Empty description={t("No data")} />
        </Spin>
      </div>
    );
}
export default withTranslation()(Addapplication);
