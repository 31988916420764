import React from "react";
import "./Landing.scss";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Hero({ t }) {
  const res = useSelector((state) => state.about);
  const navigate = useNavigate();
  return (
    <div className="landing-hero landing-container">
      <div className="landing-hero__left">
        <h1 className="landing-hero__title">Vis Nefrit</h1>
        <p className="landing-hero__text">{t("herotitle")}</p>
        {!localStorage.getItem("token") ? (
          <button
            className="landing-header__btn signup"
            type="button"
            onClick={() => navigate("/register/frist-step")}
          >
            {t("Sign up")}
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="landing-hero__right">
        {res.value?.image ? (
          <img src={res.value?.image} alt="hero img" width={350} height={350} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default withTranslation()(Hero);
