import React from "react";
import { Empty } from "antd";
import { withTranslation } from "react-i18next";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
function NotFound({ t }) {
  const navigate = useNavigate();
  return (
    <div className="not-found">
      <div className="not-found__btn">
        <button
          className="landing-header__btn signup"
          type="button"
          onClick={() => navigate(-1)}
        >
          {t("Go back")}
        </button>{" "}
      </div>
    </div>
  );
}
export default withTranslation()(NotFound);
