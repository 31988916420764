import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import "./form.scss";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import { MenuItem } from "@mui/material";
import { withTranslation, useTranslation } from "react-i18next";
import { InfoCircleOutlined } from "@ant-design/icons";
import dateFormat from "dateformat";
import { Button, Empty, notification, Spin, Tooltip } from "antd";
import axios from "axios";
import url from "../../url.json";
import headers from "../../config/headers";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import NativeSelect from "@mui/material/NativeSelect";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import SaveModal from "../Modal/SaveModal";
import PhoneInput from "react-phone-number-input";
const CustomDisableInput = styled(TextField)(() => ({
  ".MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#000",
    color: "#000",
  },
  ".MuiInputLabel-formControl.Mui-disabled": {
    WebkitTextFillColor: "#000",
    color: "#000",
  },
}));
function EditeForm2(props) {
  const [rnd, setRnd] = useState();
  const [rnd1, setRnd1] = useState();
  const [rnd2, setRnd2] = useState();
  const [rnd3, setRnd3] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState();
  const [checkstate, setCheckstate] = useState(true);
  const [checkstate1, setCheckstate1] = useState(true);
  const [checkstate2, setCheckstate2] = useState(true);
  const [checkstate3, setCheckstate3] = useState(true);
  const [text, settext] = useState(<span>{t("last_info")} </span>);
  const [text1, settext1] = useState(<span>{t("last_info")}</span>);
  const [rid, setRid] = useState();
  const [did, setDid] = useState();
  const [pid, setPid] = useState();
  const [mid, setMid] = useState();
  const [applicant, setapplicant] = useState(
    JSON.parse(localStorage.getItem("user"))?.username
  );
  const [rname, setRname] = useState();
  const [radress, setRadress] = useState();
  const [rfax, setRfax] = useState();
  const [rphone, setRphone] = useState();
  const [dname, setDname] = useState();
  const [trade_name, settrade_name] = useState();
  const [structure, setstructure] = useState();
  const [drug_figure, setdrug_figure] = useState();
  const [usage, setusage] = useState();
  const [patent, setpatent] = useState();
  const [pname, setPname] = useState();
  const [padress, setPadress] = useState();
  const [pfax, setPfax] = useState();
  const [pphone, setPphone] = useState();
  const [mname, setMname] = useState();
  const [madress, setMadress] = useState();
  const [mfax, setMfax] = useState();
  const [mphone, setMphone] = useState();
  const [err, setErr] = useState();
  const [rdata, setRdata] = useState([]);
  const [ddata, setDdata] = useState([]);
  const [pdata, setPdata] = useState([]);
  const [mdata, setMdata] = useState([]);
  const [drugd, setDrugd] = useState([]);
  const [repd, setrepd] = useState([]);
  const [drugb, setDrugb] = useState(true);
  const [repb, setrepb] = useState(true);
  const [prepd, setprepd] = useState([]);
  const [prepb, setprepb] = useState(false);
  const [mand, setmand] = useState([]);
  const [manb, setmanb] = useState(false);
  const refdrug = useRef(null);
  const [country, setCountry] = useState();
  const [drugfig, setDrugFig] = useState();
  const [drug_type, setDrug_type] = useState();
  const [drugTypes, setDrugTypes] = useState();
  const [editable, setEditable] = useState(false);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  useEffect(() => {
    axios
      .get(url.url + `/application/${props.id}`, { headers: headers() })
      .then((res) => {
        setData(res.data.data);
        if (res.data.data.editable == 1) setEditable(true);
        setrepd(res.data.data.representative);
        setCheckstate(false);
        setDrugd(res.data.data.drug);
        setCheckstate1(false);
        setMphone(res.data.data.drug.manufacturer.phone);
        setPphone(res.data.data.drug.preparer.phone);
      });
  }, []);
  useEffect(() => {
    axios.get(url.url + "/drug/figure", { headers: headers() }).then((res) => {
      setDrugFig(res.data.data);
    });
    axios.get(url.url + "/drug-type", { headers: headers() }).then((res) => {
      setDrugTypes(res.data.data);
    });
    axios
      .get(url.url + "/entity?type=representative", { headers: headers() })
      .then((res) => {
        setRdata(res.data.data);
      });
    axios
      .get(url.url + "/entity?type=preparer", { headers: headers() })
      .then((res) => {
        setPdata(res.data.data);
      });
    axios
      .get(url.url + "/entity?type=manufacturer", { headers: headers() })
      .then((res) => {
        setMdata(res.data.data);
      });
    axios.get(url.url + "/drug", { headers: headers() }).then((res) => {
      setDdata(res.data.data);
    });
    axios
      .get(url.url + "/country", { headers: headers() })
      .then((res) => {
        setCountry(res.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        toast.error(err.response.data);
      });
  }, []);
  useEffect(() => {
    if (did) {
      axios
        .get(url.url + `/drug/${did}`, { headers: headers() })
        .then((res) => {
          setDrugd(res.data.data);
          setMphone(res.data.data.manufacturer.phone);
          setPphone(res.data.data.preparer.phone);
        });
      setDrugb(true);
    }
  }, [did]);
  useEffect(() => {
    if (rid) {
      axios
        .get(url.url + `/entity/${rid}`, { headers: headers() })
        .then((res) => {
          setrepd(res.data.data);
        });
      setrepb(true);
    }
  }, [rid]);
  useEffect(() => {
    if (pid) {
      axios
        .get(url.url + `/entity/${pid}`, { headers: headers() })
        .then((res) => {
          setprepd(res.data.data);
          setPphone(res.data.data.phone);
        });
      setprepb(true);
    }
  }, [pid]);
  useEffect(() => {
    if (mid) {
      axios
        .get(url.url + `/entity/${mid}`, { headers: headers() })
        .then((res) => {
          setmand(res.data.data);
          setMphone(res.data.data.phone);
        });
      setmanb(true);
    }
  }, [mid]);
  const handleSave = () => {
    const datas = {
      applicant: applicant,
      drug_type_id: drug_type,
      drug: {},
    };
    datas.drug["country_id"] = document.getElementById("examplecountry").value;

    datas.representative = {
      name: rname ? rname : data?.representative.name,
      address: radress ? radress : data?.representative.address,
      fax: rfax ? rfax : data?.representative.fax,
      phone: rphone ? rphone : data?.representative.phone,
    };

    datas.drug = {
      ...datas.drug,
      name: dname ? dname : data?.drug.name,
      trade_name: trade_name ? trade_name : data?.drug.trade_name,
      structure: structure ? structure : data?.drug.structure,
      drug_figure_id: document.getElementById("examplefigure").value,
      usage: usage ? usage : data.drug.usage,
      patent: patent ? patent : data?.drug.patent,
    };

    datas.drug["preparer"] = {
      name: pname ? pname : data?.drug.preparer?.name,
      address: padress ? padress : data?.drug.preparer?.address,
      fax: pfax ? pfax : data?.drug.preparer?.fax,
      phone: pphone ? pphone : data?.drug.preparer?.fax,
    };

    datas.drug["manufacturer"] = {
      name: mname ? mname : data?.drug.preparer?.name,
      address: madress ? madress : data?.drug.preparer?.address,
      fax: mfax ? mfax : data?.drug.preparer?.fax,
      phone: mphone ? mphone : data?.drug.preparer?.fax,
    };

    axios
      .put(url.url + `/application/${searchParams.get("id")}`, datas, {
        headers: headers(),
      })
      .then((res) => {
        localStorage.setItem("applic", res.data.data.id);
        if (res.status == 200) {
          setErr();
          toast.success(t("Success!"));
          if (show) {
            if (searchParams.get("con_id"))
              navigate({
                pathname: "/account/applications/instruction",
                search: `?${createSearchParams({
                  id: searchParams.get("id"),
                  con_id: searchParams.get("con_id"),
                })}`,
              });
            else
              navigate({
                pathname: "/account/applications/instruction",
                search: `?${createSearchParams({
                  id: searchParams.get("id"),
                })}`,
              });
            setShow(false);
          }
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        setErr(err.response.data.errors);
      });
  };
  const [show, setShow] = useState(false);
  const handlenext = () => {
    if (
      rname ||
      radress ||
      rfax ||
      rphone ||
      rid ||
      mname ||
      madress ||
      mfax ||
      mphone ||
      mid ||
      did ||
      pid ||
      dname ||
      trade_name ||
      structure ||
      drug_figure ||
      usage ||
      patent ||
      pname ||
      padress ||
      pfax ||
      pphone ||
      dname ||
      trade_name ||
      structure ||
      drug_figure ||
      usage ||
      patent
    ) {
      setShow(true);
    } else if (searchParams.get("con_id"))
      navigate({
        pathname: "/account/applications/instruction",
        search: `?${createSearchParams({
          id: searchParams.get("id"),
          con_id: searchParams.get("con_id"),
        })}`,
      });
    else
      navigate({
        pathname: "/account/applications/instruction",
        search: `?${createSearchParams({
          id: searchParams.get("id"),
        })}`,
      });
  };
  const closeShow = () => {
    setShow(false);
  };
  const handeCancel = () => {
    setShow(false);
    if (searchParams.get("con_id"))
      navigate({
        pathname: "/account/applications/instruction",
        search: `?${createSearchParams({
          id: searchParams.get("id"),
          con_id: searchParams.get("con_id"),
        })}`,
      });
    else
      navigate({
        pathname: "/account/applications/instruction",
        search: `?${createSearchParams({
          id: searchParams.get("id"),
        })}`,
      });
  };

  if (data)
    return (
      <div>
        <SaveModal
          show={show}
          handleCancel={() => handeCancel()}
          handleok={() => handleSave()}
          closeShow={() => closeShow()}
        />
        <div className="form-body-wrapp">
          <div className="frm-con" style={{ width: "100%" }}>
            <FormControl fullWidth>
              <div className="app-edit-title-wrapper">
                <h5 className="app-edit-title">{t("Applicant_Information")}</h5>
              </div>
              {!err?.["applicant"] ? (
                <CustomDisableInput
                  defaultValue={data?.applicant}
                  className="text-field mb-2"
                  fullWidth
                  label={t("Applicant")}
                  variant="standard"
                  type="text"
                  onChange={(e) => setapplicant(e.target.value)}
                />
              ) : (
                <CustomDisableInput
                  error
                  helperText={err?.["applicant"]}
                  defaultValue={data?.applicant}
                  className="text-field mb-2"
                  fullWidth
                  label={t("Applicant")}
                  variant="standard"
                  type="text"
                  onChange={(e) => setapplicant(e.target.value)}
                />
              )}

              <div>
                {err?.["representative.name"] ? (
                  <CustomDisableInput
                    error
                    helperText={err?.["representative.name"]}
                    className="text-field mb-2"
                    fullWidth
                    label={t("Representative of the applicant")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setRname(e.target.value)}
                    defaultValue={data?.representative.name}
                  />
                ) : (
                  <CustomDisableInput
                    // defaultValue={data?.representative.name}
                    className="text-field mb-2"
                    fullWidth
                    label={t("Representative of the applicant")}
                    variant="standard"
                    type="text"
                    onChange={(e) => {
                      setRname(e.target.value);
                    }}
                  />
                )}

                <div>
                  {!err?.["representative.address"] ? (
                    <CustomDisableInput
                      defaultValue={repd?.address}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setRadress(e.target.value)}
                    />
                  ) : (
                    <CustomDisableInput
                      error
                      helperText={err?.["representative.address"]}
                      defaultValue={repd?.address}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setRadress(e.target.value)}
                    />
                  )}
                </div>
                <div>
                  {!err?.["representative.fax"] ? (
                    <CustomDisableInput
                      defaultValue={repd?.fax}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Fax address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setRfax(e.target.value)}
                    />
                  ) : (
                    <CustomDisableInput
                      error
                      helperText={err?.["representative.fax"]}
                      defaultValue={repd?.fax}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Fax address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setRfax(e.target.value)}
                    />
                  )}
                </div>

                <div>
                  <span
                    className="titleee"
                    style={drugb ? { color: "#000" } : {}}
                  >
                    {t("Phone number")}
                  </span>
                  <PhoneInput
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    className={
                      err?.["representative.phone"]
                        ? "inpp-countryy err-phone"
                        : "inpp-countryy"
                    }
                    international
                    defaultCountry="UZ"
                    value={repd?.phone}
                    onChange={(e) => setRphone(e)}
                  />
                  {err?.["representative.phone"] ? (
                    <div className="invalid-phone">
                      {err?.["representative.phone"]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* )} */}
              </div>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: 50 }}>
              <div className="app-edit-title-wrapper">
                <h5 className="app-edit-title">{t("Drug Information")}</h5>
              </div>
              <div>
                {err?.["drug_type_id"] ? (
                  <CustomDisableInput
                    error
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    select
                    label={t("Drug_type")}
                    onChange={(e) => setDrug_type(e.target.value)}
                    helperText={err?.["drug_type_id"]}
                    variant="standard"
                  >
                    {drugTypes?.map((option, i) => (
                      <MenuItem
                        key={i}
                        value={option.id}
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "red",
                          },
                        }}
                        style={{ backgroundColor: "transparent" }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CustomDisableInput>
                ) : (
                  <CustomDisableInput
                    defaultValue={data?.drug_type_id}
                    className="appp-inp text-field mb-2 mt-2"
                    fullWidth
                    select
                    label={t("Drug_type")}
                    onChange={(e) => setDrug_type(e.target.value)}
                    helperText=""
                    variant="standard"
                  >
                    {drugTypes?.map((option, i) => (
                      <MenuItem
                        key={i}
                        value={option.id}
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "red",
                          },
                        }}
                        style={{ backgroundColor: "transparent" }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </CustomDisableInput>
                )}
                {err?.["drug.name"] ? (
                  <CustomDisableInput
                    error
                    helperText={err?.["drug.name"]}
                    className="text-field mb-2"
                    fullWidth
                    label={t("Drug")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setDname(e.target.value)}
                  />
                ) : (
                  <CustomDisableInput
                    className="text-field mb-2"
                    fullWidth
                    label={t("Drug")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setDname(e.target.value)}
                    defaultValue={drugd?.name}
                  />
                )}

                {err?.["drug.trade_name"] ? (
                  <CustomDisableInput
                    className="text-field mb-2"
                    error
                    helperText={err?.["drug.trade_name"]}
                    fullWidth
                    label={t("Trade name")}
                    variant="standard"
                    type="text"
                    onChange={(e) => settrade_name(e.target.value)}
                  />
                ) : (
                  <div>
                    <CustomDisableInput
                      defaultValue={drugd?.trade_name}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Trade name")}
                      variant="standard"
                      type="text"
                      onChange={(e) => settrade_name(e.target.value)}
                    />
                  </div>
                )}
                <div>
                  {!err?.["drug.structure"] ? (
                    <CustomDisableInput
                      defaultValue={drugd?.structure}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Content")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setstructure(e.target.value)}
                    />
                  ) : (
                    <CustomDisableInput
                      error
                      helperText={err?.["drug.structure"]}
                      defaultValue={drugd?.structure}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Content")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setstructure(e.target.value)}
                    />
                  )}
                </div>

                <div>
                  <span
                    className="titleee"
                    style={drugb ? { color: "#000" } : {}}
                  >
                    {t("Drug form")}
                  </span>
                  <FormControl
                    fullWidth
                    className="appp-inp text-field mb-2 mt-2"
                  >
                    <NativeSelect
                      style={drugb ? { color: "#000" } : {}}
                      inputProps={{
                        id: "examplefigure",
                      }}
                    >
                      {drugfig?.map((res, i) => (
                        <option
                          key={i}
                          value={res.id}
                          selected={res.id == drugd?.drug_figure?.id}
                        >
                          {res.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>
                <div>
                  {!err?.["drug.usage"] ? (
                    <CustomDisableInput
                      ref={refdrug}
                      defaultValue={drugd?.usage}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Applicat")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setusage(e.target.value)}
                    />
                  ) : (
                    <CustomDisableInput
                      error
                      helperText={err?.["drug.usage"]}
                      ref={refdrug}
                      defaultValue={drugd?.usage}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Applicat")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setusage(e.target.value)}
                    />
                  )}
                </div>
                {err?.["drug.patent"] ? (
                  <CustomDisableInput
                    error
                    defaultValue={drugd?.patent}
                    helperText={err?.["drug.patent"]}
                    className="text-field mb-2"
                    fullWidth
                    label={t("Patent")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setpatent(e.target.value)}
                  />
                ) : (
                  <div>
                    <CustomDisableInput
                      defaultValue={drugd?.patent}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Patent")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setpatent(e.target.value)}
                    />
                  </div>
                )}
                <div>
                  <span
                    className="titleee"
                    style={drugb ? { color: "#90a0b7" } : {}}
                  >
                    {t("Country")}
                  </span>
                  <FormControl
                    fullWidth
                    className="appp-inp text-field mb-2 mt-2"
                  >
                    <NativeSelect
                      inputProps={{
                        id: "examplecountry",
                      }}
                    >
                      {country?.data.map((res, i) => (
                        <option
                          key={i}
                          value={res.id}
                          selected={res.id == drugd?.country?.id}
                        >
                          {res.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>
              <div>
                {err?.["drug.preparer.name"] ? (
                  <CustomDisableInput
                    error
                    helperText={err?.["drug.preparer.name"]}
                    className="text-field mb-2"
                    fullWidth
                    label={t("Preparer")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setPname(e.target.value)}
                  />
                ) : (
                  <div>
                    <CustomDisableInput
                      defaultValue={drugd.preparer?.name}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Preparer")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setPname(e.target.value)}
                    />
                  </div>
                )}
                <div>
                  {!err?.["drug.preparer.address"] ? (
                    <CustomDisableInput
                      defaultValue={drugd.preparer?.address}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setPadress(e.target.value)}
                    />
                  ) : (
                    <CustomDisableInput
                      error
                      helperText={err?.["drug.preparer.address"]}
                      defaultValue={drugd.preparer?.address}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setPadress(e.target.value)}
                    />
                  )}
                </div>
                <div>
                  {!err?.["drug.preparer.fax"] ? (
                    <CustomDisableInput
                      defaultValue={drugd.preparer?.fax}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Fax address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setPfax(e.target.value)}
                    />
                  ) : (
                    <CustomDisableInput
                      error
                      helperText={err?.["drug.preparer.fax"]}
                      defaultValue={drugd.preparer?.fax}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Fax address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setPfax(e.target.value)}
                    />
                  )}
                </div>
                <div>
                  <span
                    className="titleee"
                    style={drugb ? { color: "#000" } : {}}
                  >
                    {t("Phone number")}
                  </span>
                  <PhoneInput
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    className={
                      err?.["drug.preparer.phone"]
                        ? "inpp-countryy err-phone"
                        : "inpp-countryy"
                    }
                    international
                    defaultCountry="UZ"
                    value={pphone}
                    onChange={(e) => setPphone(e)}
                  />
                  {err?.["drug.preparer.phone"] ? (
                    <div className="invalid-phone">
                      {err?.["drug.preparer.phone"]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>{" "}
              <div>
                {err?.["drug.manufacturer.name"] ? (
                  <CustomDisableInput
                    error
                    helperText={err?.["drug.manufacturer.name"]}
                    className="text-field mb-2"
                    fullWidth
                    label={t("Manufacturer")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setMname(e.target.value)}
                  />
                ) : (
                  <div>
                    <CustomDisableInput
                      defaultValue={drugd.manufacturer?.name}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Manufacturer")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setMname(e.target.value)}
                    />
                  </div>
                )}
                <div>
                  {!err?.["drug.manufacturer.address"] ? (
                    <CustomDisableInput
                      defaultValue={drugd.manufacturer?.address}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setMadress(e.target.value)}
                    />
                  ) : (
                    <CustomDisableInput
                      error
                      helperText={err?.["drug.manufacturer.address"]}
                      defaultValue={drugd.manufacturer?.address}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setMadress(e.target.value)}
                    />
                  )}
                </div>
                <div>
                  {!err?.["drug.manufacturer.fax"] ? (
                    <CustomDisableInput
                      defaultValue={drugd.manufacturer?.fax}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Fax address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setMfax(e.target.value)}
                    />
                  ) : (
                    <CustomDisableInput
                      error
                      helperText={err?.["drug.manufacturer.fax"]}
                      defaultValue={drugd.manufacturer?.fax}
                      className="text-field mb-2"
                      fullWidth
                      label={t("Fax address")}
                      variant="standard"
                      type="text"
                      onChange={(e) => setMfax(e.target.value)}
                    />
                  )}
                </div>
                {err?.["drug.manufacturer.phone"] ? (
                  <CustomDisableInput
                    error
                    helperText={err?.["drug.manufacturer.phone"]}
                    className="text-field mb-2"
                    fullWidth
                    label={t("Phone number")}
                    variant="standard"
                    type="text"
                    onChange={(e) => setMphone(e.target.value)}
                  />
                ) : (
                  <div>
                    <span
                      className="titleee"
                      style={drugb ? { color: "#000" } : {}}
                    >
                      {t("Phone number")}
                    </span>
                    <PhoneInput
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      className={
                        err?.["drug.manufacturer.phone"]
                          ? "inpp-countryy err-phone"
                          : "inpp-countryy"
                      }
                      international
                      defaultCountry="UZ"
                      value={mphone}
                      onChange={(e) => setMphone(e)}
                    />
                    {err?.["drug.manufacturer.phone"] ? (
                      <div className="invalid-phone">
                        {err?.["drug.manufacturer.phone"]}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <div
                  className="info-header__wrapper date-form text-right"
                  style={{ justifyContent: "flex-end" }}
                >
                  <i className="info-header__icon bx bx-calendar date-icon-form"></i>
                  <span className="info-header__text ">
                    {dateFormat(new Date(), "dd.mm.yyyy")}
                  </span>
                </div>
              </div>
            </FormControl>
          </div>
        </div>

        <div className="row justify-content-end mt-3 mr-1">
          <div className="mr-1">
            <Button
              className="button button-edit-inner"
              type="primary"
              size={18}
              onClick={handlenext}
            >
              {t("Next")}
            </Button>
          </div>
          <div className="mr-1">
            <Button
              className="button button-edit-inner"
              type="primary"
              size={18}
              onClick={handleSave}
            >
              {t("Save")}
            </Button>
          </div>
          <Button
            className="button button-edit-inner"
            type="primary"
            size={18}
            onClick={() => navigate("/account/applications")}
          >
            {t("Cancel")}
          </Button>
        </div>
      </div>
    );
  else
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 500,
          backgroundColor: "white",
          borderRadius: 20,
        }}
      >
        <Spin tip={t("Loading...")}>
          <Empty description={t("No data")} />
        </Spin>
      </div>
    );
}

export default withTranslation()(EditeForm2);
