import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "./styles.scss";
import url from "../../url.json";
import { withTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Laststep from "../../components/ChekoutPage/Laststep";

function LastReg({ t }) {
  const steps = [t("First step"), t("Last step")];
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);


  return (
    <div className="wrapper">
      <div className="forms">
        <div className="title">{t("Sign up")}</div>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={1} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel className="ll">{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Laststep />
      </div>
    </div>
  );
}
export default withTranslation()(LastReg);
