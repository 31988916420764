import * as React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./styles.scss";
import url from "../../url.json";
import axios from "axios";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  FormFeedback,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import headers from "../../config/headers.js";
import { useNavigate } from "react-router-dom";
function Laststep({ t }) {
  const [errorlast, setErrorlast] = useState();
  const navigate = useNavigate();
  const [valuePhone, setValuePhone] = useState();

  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
  const handleChangeLast = () => {
    const names = document.getElementById("name").value;
    const bossname = document.getElementById("bossname").value;
    const website = document.getElementById("examplewebsite").value;
    const address = document.getElementById("exampleaddress").value;
    const inn = document.getElementById("exampleinn").value;

    const data = {
      name: names,
      boss_name: bossname,
      website: website,
      address: address,
      phone: valuePhone,
      inn: inn,
      status: "acrive",
    };

    axios
      .post(url.url + "/legal-entity", data, { headers: headers() })
      .then((res) => {
        if (res.status == 201) {
          const user = JSON.parse(localStorage.getItem("user"));
          user.legal_entity = res.data.data;
          localStorage.setItem("user", JSON.stringify(user));
          window.location.href = "/account/applications";

          // navigate("/account/applications");
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        setErrorlast(err.response.data.errors);
        toast.error(err.response.data.error);
      });
  };

  return (
    <Form>

        <div>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className="lab" for="name">
                  {t("Organization name")}
                </Label>
                {!errorlast?.name ? (
                  <Input
                    className="inpp"
                    id="name"
                    name="name"
                    placeholder={t("Organization name enter")}
                  />
                ) : (
                  <Input
                    id="name"
                    invalid
                    className="inpp invalid"
                    name="name"
                    placeholder={t("Organization name enter")}
                  />
                )}
                {errorlast ? <FormFeedback>{errorlast.name}</FormFeedback> : ""}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className="lab" for="bossname">
                  {t("Boss name")}
                </Label>
                {!errorlast?.boss_name ? (
                  <Input
                    className="inpp"
                    id="bossname"
                    placeholder={t("Boss name enter")}
                  />
                ) : (
                  <Input
                    id="bossname"
                    invalid
                    className="inpp invalid"
                    placeholder={t("Boss name enter")}
                  />
                )}
                {errorlast ? (
                  <FormFeedback>{errorlast.boss_name}</FormFeedback>
                ) : (
                  ""
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className="lab" for="exampleinn">
                  {t("Inn")}
                </Label>
                {!errorlast?.inn ? (
                  <Input
                    className="inpp"
                    id="exampleinn"
                    placeholder={t("inn enter")}
                    name="inn"
                  />
                ) : (
                  <Input
                    id="exampleinn"
                    invalid
                    className="inpp invalid"
                    placeholder={t("inn enter")}
                  />
                )}
                {errorlast ? <FormFeedback>{errorlast.inn}</FormFeedback> : ""}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className="lab" for="exampleaddress">
                  {t("Address")}
                </Label>
                {!errorlast?.address ? (
                  <Input
                    className="inpp"
                    id="exampleaddress"
                    placeholder={t("adress enter")}
                  />
                ) : (
                  <Input
                    id="exampleaddress"
                    invalid
                    className="inpp invalid"
                    placeholder={t("adress enter")}
                  />
                )}
                {errorlast ? (
                  <FormFeedback>{errorlast.address}</FormFeedback>
                ) : (
                  ""
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className="lab" for="examplewebsite">
                  {t("Website")}
                </Label>
                {!errorlast?.website ? (
                  <Input
                    className="inpp"
                    id="examplewebsite"
                    placeholder={t("website enter")}
                  />
                ) : (
                  <Input
                    id="examplewebsite"
                    invalid
                    className="inpp invalid"
                    placeholder={t("website enter")}
                  />
                )}
                {errorlast ? (
                  <FormFeedback>{errorlast.website}</FormFeedback>
                ) : (
                  ""
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className="lab" for="examplephone">
                  {t("Phone")}
                </Label>

                <PhoneInput
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  className={
                    errorlast?.phone ? "inpp-country err-phone" : "inpp-country"
                  }
                  international
                  defaultCountry="UZ"
                  value={valuePhone}
                  onChange={(e) => setValuePhone(e)}
                />
                {errorlast ? (
                  <div className="invalid-phone">{errorlast.phone}</div>
                ) : (
                  ""
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <button className="btnn" onClick={handleChangeLast} type="button">
                {t("Sign up")}
              </button>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5 mb-5">
            <button
                className="btnn"
                onClick={() =>
                    (window.location.href = `https://esi.uz/oauth2/authorize?client_id=091544BAEF926D20&scope=public-info+certificate-info&response_type=code&state={"custom":"state"}&redirect_uri=https://nefrit.vetgov.uz`)
                }
                type="button"
            >
              {t("Registration through ERI")}
            </button>
          </Row>
        </div>
    </Form>
  );
}
export default withTranslation()(Laststep);
