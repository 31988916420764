import React, { useState } from "react";
import "../../components/Pay/styles.scss";
import { withTranslation, useTranslation } from "react-i18next";
import { Empty } from "antd";
import { useSelector } from "react-redux";

function ApplicNone(props) {
  const user = useSelector((state) => state.profile?.data);

  const { t } = useTranslation();
  return (
    <div className="cards-body">
      <div className="cards justify-content-center">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Empty
            style={{ fontSize: 16, fontStyle: "italic" }}
            description={
              <div>
                <p>{props.title}</p>
                <p>{user[0].data.legal_entity?.block_reason}</p>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(ApplicNone);
