import React, { useState } from "react";
import "./MainHeader.scss";

export default function MainHeader(props) {
  return (
    <div className="main-header">
      <div className="main-header__inner header-inner">
        <h3 className="header-inner__title">{props.title}</h3>
        <div className="header-inner__act">
          {props.name ? (
            <select className="header-inner__sel" name="sort">
              <option value="user">{props.name}</option>
            </select>
          ) : (
            <></>
          )}
          {props.children}
          {props.btn ? (
            <button className="header-inner__btn" type="button" onClick={props.openModal}>
              {props.btn}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
