import { Button, Modal } from "antd";
import "./styles.scss";
import {
  RetweetOutlined,
  FileAddOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileJpgOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modalpayReducer } from "../../Redux/Reducers/Modal/modalpay";
import { TextField } from "@mui/material";
import { withTranslation, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import headers from "../../config/headers";
import url from "../../url.json";
import axios from "axios";
function ModalPay(props) {
  const { t } = useTranslation();
  const show = useSelector((state) => state.modalpay);
  const [visible, setVisible] = useState(false);
  const [icon, setIcon] = useState();
  const dispatch = useDispatch();
  const fileRef = React.createRef();
  const [file, setFile] = useState();
  const [names, setName] = useState();
  const [title, setTitle] = useState();
  const [amount, setamount] = useState();
  const openFile = () => {
    fileRef.current.click();
  };
  function handleChange(event) {
    if (event.target.files[0]) setFile(event.target.files[0]);
    else setFile();
  }
  useEffect(() => {
    if (props.file?.name.split(".")[1] == "jpg") setIcon(<FileJpgOutlined />);
    if (props.file?.name.split(".")[1] == "pdf") setIcon(<FilePdfOutlined />);
    if (props.file?.name.split(".")[1] == "doc") setIcon(<FileWordOutlined />);
    if (props.file?.name.split(".")[1] == "docx") setIcon(<FileWordOutlined />);
    if (props.file?.name.split(".")[1] == "ppt") setIcon(<FilePptOutlined />);
    if (props.file?.name.split(".")[1] == "pptx") setIcon(<FilePptOutlined />);
    setVisible(show.value);
    setFile(props.file);
    setTitle(props.file?.name.split(".")[0]);
  }, [show.value]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleOk = () => {
    const data = new FormData();
    data.append("application_contract_id", props.id);
    data.append("file", file);
    data.append("amount", amount);
    setConfirmLoading(true);
    axios
      .post(url.url + "/payment", data, { headers: headers() })
      .then((res) => {
        if (res.status == 200) {
          dispatch(modalpayReducer(false));
          setVisible(false);
          setConfirmLoading(false);
          setamount();
          toast.success(t("Success!"));
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        if (err.response.data.errors.amount[0])
          toast.error(err.response.data.errors.amount[0]);
        setConfirmLoading(false);
      });
  };
  const handleCancel = () => {
    dispatch(modalpayReducer(false));
    setVisible(false);
    setamount();
    setFile();
  };
  return (
    <>
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <Modal
        title={t("Pay ")}
        visible={visible}
        onOk={handleOk}
        okText={t("Ok")}
        cancelText={t("Cancel")}
        confirmLoading={confirmLoading}
        onCancel={() => {
          dispatch(modalpayReducer(false));
          setVisible(false);
          setamount();
          setFile();
          props.handleCancel();
        }}
      >
        <div className="mod-container">
          <div className="mod-inner">
            <div className="mod-inner-holder">
              <div className="mod-icon">{icon}</div>
              <div className="mod-file">
                <h3 className="mod-file-title">{file?.name}</h3>
                {file ? (
                  <span className="mod-file-name">
                    {Math.ceil(file?.size / 1024)} kb
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mod-inner-icon">
              <RetweetOutlined onClick={openFile} />
            </div>
          </div>
          <div key={file?.name}>
            <TextField
              className="text-field"
              fullWidth
              id="standard-basic"
              label={t("Amount")}
              variant="standard"
              type="text"
              onChange={(e) => setamount(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
export default withTranslation()(ModalPay);
