import React from "react";
export default function YandexMap(props) {
  return (
    <div>
      <iframe
        src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11998.016013726896!2d${props.point_x}!3d${props.point_y}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae896526ca9f53%3A0xedef241108951496!2sIlmiy%20markaz!5e0!3m2!1sru!2s!4v1658753202615!5m2!1sru!2s`}
        height="450"
        width="100%"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
