import React, { useEffect, useState } from "react";
import "./styles.scss";
import url from "../../url.json";
import { useDispatch, useSelector } from "react-redux";
import Delete from "../../assets/icon/delete.svg";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { withTranslation } from "react-i18next";
import axios from "axios";
import headers from "../../config/headers";
import dateFormat from "dateformat";
import { Empty } from "antd";
import "antd/dist/antd.css";
import { Button, Dropdown, Menu } from "antd";
import Dowolland from "../Icons/Dowolland";
import { Alert, Spin } from "antd";
import { Table } from "reactstrap";
import ModalPayDel from "../Modal/ModalPayDel";
import { modalpayReducer } from "../../Redux/Reducers/Modal/modalpay";
import { modalpaydelReducer } from "../../Redux/Reducers/Modal/modalpaydel";
import {
  DownloadOutlined,
  FileAddOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import ModalPay from "../Modal/ModalPay";
import ModalApplic from "../Modal/ModalApplic";
import { modalapplicReducer } from "../../Redux/Reducers/Modal/modalapplic";
function ContractPay({ t }) {
  const lan = useSelector((state) => state.langReducer);
  const modalpay = useSelector((state) => state.modalpay);
  const modalpaydel = useSelector((state) => state.modalpaydel);
  const [searchParams, setSearchParams] = useSearchParams();
  const [shows, setShows] = useState(false);
  const [datares, setDatares] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [datas, setData] = useState();
  const [dataId, setDataId] = useState();
  const fileRef = React.createRef();
  const [file, setFile] = useState();
  const [contract, setContract] = useState();
  const fileRefs = React.createRef();
  const [files, setFiles] = useState();
  const showModal = () => {
    dispatch(modalapplicReducer(true));
  };
  function handleShow(id) {
    axios
      .get(url.url + `/application/${id}`, {
        headers: headers(),
      })
      .then((res) => {
        localStorage.setItem("applic", res.data.data.id);
        if (res.contract_id == null)
          navigate({
            pathname: "/account/applications/show",
            search: `?${createSearchParams({
              id: res.data.data.id,
            })}`,
          });
        else
          navigate({
            pathname: "/account/applications/show",
            search: `?${createSearchParams({
              id: res.data.data.id,
              con_id: res.data.data.contract_id,
            })}`,
          });
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }
  const openFile = (a) => {
    setDataId(a);
    fileRef.current.click();
  };
  function handleChange(event) {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      showModal();
    }
  }
  const showModals = () => {
    dispatch(modalpayReducer(true));
  };
  const openFiles = (a) => {
    fileRefs.current.click();
  };
  function handleChanges(event) {
    setFiles(event.target.files[0]);
    if (event.target.files[0]) {
      showModals();
    }
  }
  function handleChange(event) {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      showModal();
    }
  }
  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    axios
      .get(url.url + `/contracts/${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        setContract(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }, [lan, shows, modalpaydel, modalpay]);
  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    axios
      .get(url.url + `/payment?contract=${searchParams.get("id")}`, {
        headers: headers(),
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
      });
  }, [modalpay.value, lan, shows]);
  const handleCancel = () => {
    setShows(false);
    fileRef.current.value = "";
    fileRefs.current.value = "";
  };
  const menu = (res) => {
    if (res?.verified_at == null)
      return (
        <Menu
          items={[
            {
              label: t("Delete"),
              key: "1",
              icon: <img className="btn-icon" src={Delete} alt="delete icon" />,
              onClick: () => {
                setShows(true);
                setDatares(res);
              },
            },

            {
              label: t("Dowolland"),
              key: "2",
              icon: <Dowolland width={18} height={18} />,
              onClick: () => {
                window.location.href = res?.file;
              },
            },
          ]}
        />
      );
    else
      return (
        <Menu
          items={[
            {
              label: t("Dowolland"),
              key: "1",
              icon: <Dowolland width={18} height={18} />,
              onClick: () => {
                window.location.href = res?.file;
              },
            },
          ]}
        />
      );
  };
  return (
    <div className="pay_list_wrap">
      <ModalPayDel show={shows} handleCancel={handleCancel} res={datares} />
      <ModalPay
        file={files}
        id={searchParams.get("id")}
        handleCancel={handleCancel}
      />
      <input
        type="file"
        id="files"
        ref={fileRefs}
        onChange={handleChanges}
        style={{ display: "none" }}
      />
      <ModalApplic
        file={file}
        id={searchParams.get("id")}
        handleCancel={handleCancel}
      />
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <div className="pay-wrapper">
        <div className="pay-wrapper__id pay-wrapper__infoo">
          <div className="pay-wrapper__head">
            <h3 className="pay-wrapper__tt">{t("Contract")}</h3>
          </div>
          <div className="pay-wrapper__body payy-body">
            <ul className="pay-wrapper__list">
              <li className="pay-wrapper__item">
                <span className="pay-wrapper__title">
                  {t("Contract name")}:
                </span>
                <p className="pay-wrapper__name">{contract?.name}</p>
              </li>
              <li className="pay-wrapper__item">
                <span className="pay-wrapper__title">{t("Amount")}:</span>
                <p className="pay-wrapper__name"> {contract?.payment_amount}</p>
              </li>
              <li className="pay-wrapper__item">
                <span className="pay-wrapper__title">{t("Status")}:</span>
                <p
                  className={
                    contract?.is_paid == 1
                      ? "pay-wrapper__status pay"
                      : "pay-wrapper__status not"
                  }
                >
                  {contract?.is_paid == 1 ? t("paid") : t("unpaid")}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="pay-wrapper__table">
          <Table
            responsive="sm"
            style={{ backgroundColor: "white", borderRadius: 15, margin: 0 }}
          >
            <thead>
              <tr className="pp-tr">
                <th className="pp-th">{t("Template")}</th>
                <th className="pp-th">{t("Contract_upload")}</th>

                <th className="pp-th">{t("Contract")}</th>
                <th className="pp-th">{t("Full Contract")}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td className="pp-td">
                  <Button
                    className="btnnn"
                    type="primary"
                    icon={<DownloadOutlined />}
                    size={50}
                    onClick={() =>
                      (window.location.href = contract.template_file)
                    }
                  ></Button>
                </td>
                <td className="pp-td">
                  <Button
                    disabled={contract?.is_paid == 1 ? true : false}
                    className="btnnn"
                    type="primary"
                    icon={<UploadOutlined />}
                    onClick={openFile}
                    size={50}
                  ></Button>
                </td>
                <td className="pp-td">
                  <Button
                    disabled={contract?.legal_file ? false : true}
                    className="btnnn"
                    type="primary"
                    icon={<DownloadOutlined />}
                    size={50}
                    onClick={() =>
                      (window.location.href = contract?.legal_file)
                    }
                  ></Button>
                </td>
                <td className="pp-td">
                  <Button
                    className="btnnn"
                    disabled={contract?.file ? false : true}
                    type="primary"
                    icon={<DownloadOutlined />}
                    size={50}
                    onClick={() => (window.location.href = contract?.file)}
                  ></Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="pay-wrapper__id">
          <div className="pay-wrapper__head">
            <h3 className="pay-wrapper__tt">{t("Applications")}</h3>
            <ul className="pay-wrapper__info">
              <li className="pay-wrapper__item">
                <span className="pay-wrapper__item-tt payed">{t("paid")}</span>
              </li>
              <li className="pay-wrapper__item">
                <span className="pay-wrapper__item-tt not-payed">
                  {t("unpaid")}
                </span>
              </li>
            </ul>
          </div>
          <div className="pay-wrapper__body">
            {contract?.applications.map((res, i) => (
              <div
                key={i}
                className="pay-wrapper__holder"
                onClick={() => handleShow(res.id)}
              >
                <div className="pay-wrapper__holder">
                  <span
                    className={
                      res.is_paid == 1
                        ? "pay-wrapper__dot pay"
                        : "pay-wrapper__dot not"
                    }
                  ></span>
                  <p className="pay-wrapper__num">{res?.code}</p>
                </div>
                <p className="drug-name">{res.drug}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pay-table__head">
        <h3 className="pay-table__head-tt">{t("reciepts")}</h3>
        <Button
          type="primary"
          disabled={
            contract?.is_paid == 1 || contract?.file == null ? true : false
          }
          icon={<FileAddOutlined />}
          onClick={openFiles}
          size={50}
        >
          {t("Upload receipt")}
        </Button>
      </div>
      {datas ? (
        <Table
          responsive="sm"
          style={{ backgroundColor: "white", borderRadius: 15 }}
        >
          <thead>
            <tr>
              <th className="pay-thh">#</th>
              <th className="pay-thh">{t("File")}</th>
              <th className="pay-thh">{t("Amount")}</th>
              <th className="pay-thh">{t("Status")}</th>
              <th className="pay-thh">{t("Created at")}</th>
              <th className="pay-thh">{t("Verified at")}</th>
              <th className="pay-thh">{t("Action")}</th>
            </tr>
          </thead>
          <tbody>
            {datas?.map((res, i) => (
              <tr key={i}>
                <td className="pay-tdd">{i + 1}</td>
                <td className="pay-tdd">
                  {res.file?.split("/")[res.file?.split("/").length - 1]}
                </td>
                <td className="pay-tdd">{res.amount}</td>
                <td className="pay-tdd">
                  <div
                    className={`btn out btn-outline-${res.status.color}  status-name`}
                  >
                    {res.status.name}
                  </div>
                </td>
                <td className="pay-tdd">
                  {dateFormat(res.created_at, "dd.mm.yyyy")}
                </td>
                <td className="pay-tdd">
                  {res.verified_at !== null
                    ? dateFormat(res.verified_at, "dd.mm.yyyy")
                    : "--.--.----"}
                </td>

                <td className="pay-tdd">
                  <Dropdown
                    overlay={menu(res)}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <i className="header-list__icon bx bx-dots-vertical-rounded"></i>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 500,
            backgroundColor: "white",
            borderRadius: 15,
          }}
        >
          <Spin tip="Loading...">
            <Empty description={t("No data")} />
          </Spin>
        </div>
      )}
    </div>
  );
}
export default withTranslation()(ContractPay);
