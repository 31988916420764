import React from "react";

export default function Youtube(props) {
  return (
    <div>
      <div className="landing-blog__box box-blog">
        <div className="box-blog__link" >
          <div className="box-blog__head">
            <iframe
              src={props.data.url}
              frameBorder="0"
              className="box-blog__img"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title={props.data.title}
            />
          </div>
          <div className="box-blog__body">
            <h3 className="box-blog__title">{props.data.title}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
