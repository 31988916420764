import React, { useEffect, useState, useCallback, useRef } from "react";
import "./settings.scss";
import { Button, Checkbox, Form, Input, Radio } from "antd";
import logo from "../../assets/img/logo.png";
import url from "../../url.json";
import { set } from "date-fns";
import headers from "../../config/headers";
import Edit from "../../assets/icon/edit.svg";
import dateFormat from "dateformat";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

function Settings({ t }) {
  const [error, setError] = useState();

  const onFinish = (values) => {
    axios
      .post(url.url + "/legal-entity/password", values, { headers: headers() })
      .then((res) => {
        if (res.status == 200) {
          toast.success(t("Success!"));
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          window.location = "/";
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        // if (err.response.status == 404) window.location = "/404";
        if (err.response.status == 405) toast.error(err.response.data.error);
        if (err.response.data.errors) setError(err.response.data.errors);
        else {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
      });
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <ToastContainer style={{ zIndex: "99999999999999" }} />
      <div className="settings__info info-settings">
        <div className="w-100">
          <Form
            layout="vertical"
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="row justify-content-between ">
              <div className="col-md-4">
                <Form.Item label={t("Old password")} name="old_password">
                  <Input.Password />
                </Form.Item>
                {error?.old_password ? (
                  <span style={{ fontStyle: "italic", color: "red" }}>
                    {error?.old_password}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4">
                <Form.Item label={t("New password")} name="password">
                  <Input.Password />
                </Form.Item>
                {error?.password ? (
                  <span style={{ fontStyle: "italic", color: "red" }}>
                    {error?.password}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4">
                <Form.Item
                  label={t("Password confirmation")}
                  name="password_confirmation"
                >
                  <Input.Password />
                </Form.Item>
                {error?.password_confirmation ? (
                  <span style={{ fontStyle: "italic", color: "red" }}>
                    {error?.password_confirmation}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>

            <Form.Item>
              <div
                className="justify-content-end mt-5"
                style={{ display: "flex" }}
              >
                <Button type="primary" htmlType="submit">
                  {t("Save")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(Settings);
