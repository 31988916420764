import React from "react";
import url from "../../url.json";
import { useNavigate, createSearchParams } from "react-router-dom";
export default function BlogCard(props) {
  const navigate = useNavigate();
  const handleClick = (ids) => {
    navigate({
      pathname: "/blogopen",
      search: `?${createSearchParams({
        id: ids.data.id,
      })}`,
    });
  };
  return (
    <>
      <div
        className="landing-blog__box box-blog"
        onClick={() => handleClick(props)}
      >
        <a className="box-blog__link" href="#">
          <div className="box-blog__head">
            <img
              className="box-blog__img"
              src={props.data.image}
              alt="box image"
              width={300}
              height={200}
            />
          </div>
          <div className="box-blog__body">
            <h3 className="box-blog__title">{props.data.title}</h3>
            <div className="box-blog__text" dangerouslySetInnerHTML={{ __html: props.data.text.slice(0, 100) }}/>...

            {/* <p >{props.data.text.slice(0, 100)}...</p> */}
          </div>
        </a>
      </div>
    </>
  );
}
